import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Button, Link, Notification } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/notification/code",
  "title": "Notification - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Playground = makeShortcode("Playground");
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "inline",
      "style": {
        "position": "relative"
      }
    }}>{`Inline`}<a parentName="h4" {...{
        "href": "#inline",
        "aria-label": "inline permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<>
<Notification label="New messages">You have received new messages.</Notification>
<Notification label="Form done" type="success" style={{marginTop: 'var(--spacing-s)'}}>Form submit was successful!</Notification>
<Notification label="Slow loading" type="alert" style={{marginTop: 'var(--spacing-s)'}}>Loading is taking longer than expected.</Notification>
<Notification label="Missing information" type="error" style={{marginTop: 'var(--spacing-s)'}}>Form is missing critical information.</Notification>
</>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div>
<section aria-label="Notification" class="hds-notification">
  <div class="hds-notification__content">
    <div class="hds-notification__label" role="heading" aria-level="2">
      <span class="hds-icon hds-icon--info-circle-fill" aria-hidden="true"></span>
      <span>New messages</span>
    </div>
    <div class="hds-notification__body">You have received new messages.</div>
  </div>
</section>
<br />
<section aria-label="Notification" class="hds-notification hds-notification--success">
  <div class="hds-notification__content">
    <div class="hds-notification__label" role="heading" aria-level="2">
      <span class="hds-icon hds-icon--check-circle-fill" aria-hidden="true"></span>
      <span>Form done</span>
    </div>
    <div class="hds-notification__body">Form submit was successful!</div>
  </div>
</section>
<br />
<section aria-label="Notification" class="hds-notification hds-notification--alert">
  <div class="hds-notification__content">
    <div class="hds-notification__label" role="heading" aria-level="2">
      <span class="hds-icon hds-icon--alert-circle-fill" aria-hidden="true"></span>
      <span>Slow loading</span>
    </div>
    <div class="hds-notification__body">Loading is taking longer than expected.</div>
  </div>
</section>
<br />
<section aria-label="Notification" class="hds-notification hds-notification--error">
  <div class="hds-notification__content">
    <div class="hds-notification__label" role="heading" aria-level="2">
      <span class="hds-icon hds-icon--error-fill" aria-hidden="true"></span>
      <span>Missing information</span>
    </div>
    <div class="hds-notification__body">Form is missing critical information.</div>
  </div>
</section>
</div>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "toast",
      "style": {
        "position": "relative"
      }
    }}>{`Toast`}<a parentName="h4" {...{
        "href": "#toast",
        "aria-label": "toast permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const [open, setOpen] = React.useState(false)
  const [autoCloseOpen, setAutoCloseOpen] = React.useState(false)
  const showButtonRef = React.useRef(null)
  const showAutoCloseButtonRef = React.useRef(null)
  const onClose = () => {
    setOpen(false);
    if(showButtonRef.current) {
      showButtonRef.current.focus();
    }
  }
  const onAutoClose = () => {
    setAutoCloseOpen(false);
    if(showAutoCloseButtonRef.current) {
      showAutoCloseButtonRef.current.focus();
    }
  }
  return (
    <>
      <Button ref={showButtonRef} onClick={() => setOpen(true)}>Show toast</Button>
      <Button ref={showAutoCloseButtonRef} onClick={() => setAutoCloseOpen(true)} style={{ display: "flex", marginTop: "var(--spacing-s)" }}>Show automatically disappearing toast</Button>
      {open && (
        <Notification label="New messages" position="top-right" dismissible closeButtonLabelText="Close toast" onClose={() => onClose()} style={{ zIndex: 100 }}>
          You have received new messages.
        </Notification>
      )}
      {autoCloseOpen && (
        <Notification label="New messages" position="top-right" autoClose onClose={() => onAutoClose()} style={{ zIndex: 100 }}>
          You have received new messages.
        </Notification>
      )}
    </>
  )
}}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<section aria-label="Notification" class="hds-notification hds-notification--top-right">
  <div role="alert" class="hds-notification__content">
    <div class="hds-notification__label">
      <span class="hds-icon hds-icon--info-circle-fill" aria-hidden="true"></span>
      <span>New messages</span>
    </div>
    <div class="hds-notification__body">You have received new messages.</div>
  </div>
  <button class="hds-notification__close-button" aria-label="Close toast" type="button" onclick="">
    <span class="hds-icon hds-icon--cross" aria-hidden="true"></span>
  </button>
</section>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "notification-sizes",
      "style": {
        "position": "relative"
      }
    }}>{`Notification sizes`}<a parentName="h4" {...{
        "href": "#notification-sizes",
        "aria-label": "notification sizes permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<>
<Notification size="large" label="New messages">You have received new messages.</Notification>
<Notification size="default" label="New messages" style={{marginTop: 'var(--spacing-s)'}}>You have received new messages.</Notification>
<Notification size="small" label="New messages" style={{marginTop: 'var(--spacing-s)'}}>You have received new messages.</Notification>
</>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div>
<section aria-label="Notification" class="hds-notification hds-notification--large">
  <div class="hds-notification__content">
    <div class="hds-notification__label" role="heading" aria-level="2">
      <span class="hds-icon hds-icon--info-circle-fill" aria-hidden="true"></span>
      <span>New messages</span>
    </div>
    <div class="hds-notification__body">You have received new messages.</div>
  </div>
</section>
<br />
<section aria-label="Notification" class="hds-notification">
  <div class="hds-notification__content">
    <div class="hds-notification__label" role="heading" aria-level="2">
      <span class="hds-icon hds-icon--info-circle-fill" aria-hidden="true"></span>
      <span>New messages</span>
    </div>
    <div class="hds-notification__body">You have received new messages.</div>
  </div>
</section>
<br />
<section aria-label="Notification" class="hds-notification hds-notification--small">
  <div class="hds-notification__content">
    <div class="hds-notification__label" role="heading" aria-level="2">
      <span class="hds-icon hds-icon--info-circle-fill" aria-hidden="true"></span>
    </div>
    <div class="hds-notification__body">You have received new messages.</div>
  </div>
</section>
</div>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "invisible",
      "style": {
        "position": "relative"
      }
    }}>{`Invisible`}<a parentName="h4" {...{
        "href": "#invisible",
        "aria-label": "invisible permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Notification label="New messages" invisible>
  You have received new messages. This notification is invisible.
</Notification>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div class="hiddenFromScreen" role="alert">
  <section aria-label="Notification" class="hds-notification">
    <div class="hds-notification__content">
      <div class="hds-notification__label">
        <span class="hds-icon hds-icon--info-circle-fill" aria-hidden="true"></span>
        <span>New messages</span>
      </div>
      <div class="hds-notification__body">You have received new messages. This notification is invisible.</div>
    </div>
  </section>
</div>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-notification--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/react/src/components/notification" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/core/?path=/story/components-notification--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/core/src/components/notification" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note! You can find the full list of properties in the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-notification--default" mdxType="Link">{`React Storybook.`}</Link></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`autoClose`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the Toast notification will be closed automatically after a certain time.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boxShadow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the notification will have a box shadow.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`displayAutoCloseProgress`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, a progress is displayed on top of the Toast notification.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invisible`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the notification will be visually hidden. Useful when notification should only be "seen" by screen readers.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label of the notification.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`notificationAriaLabel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The aria-label of the notification section element.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"Notification"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onClose`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback fired when the notification is closed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of the notification. Affects the colour and icon of the notification.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"info"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"success"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"alert"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"error"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"info"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`position`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The position of the notification.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"inline"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"top-left"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"top-center"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"top-right"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"bottom-left"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"bottom-center"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"bottom-right"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"inline"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the notification.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"small"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"default"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"large"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"default"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dismissible`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the notification can be closed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`closeButtonLabelText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The aria-label and title for the close button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Notification properties]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      